import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en.json';
import es from './es.json';

const resources = {
    en: {
        lang: en
    },
    es: {
      lang: es              
    }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    fallbackLng: 'en',
    lang: "en",     
    interpolation: {
      escapeValue: false 
    }
  })

export default i18n;