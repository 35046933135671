import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Headsite from "./components/Headsite";
import ContactUs from "./pages/ContactUs";
import Service from "./pages/Service";
import Footer from "./components/Footer";
import {  useEffect } from "react";
import { useTranslation } from "react-i18next";
import Error404 from "./pages/404";

const Tilventis = () => {
  
  const { i18n } = useTranslation('lang');
  useEffect(() => {
    i18n.changeLanguage('en').catch(err => console.log(err))
  }, [])

  return (
    <BrowserRouter>
        <Headsite />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/services/:typeService' element={<Service />} />
          <Route path='*' element={<Error404/>}/>
        </Routes>
        <Footer />
    </BrowserRouter>
  );
}

export default Tilventis;
