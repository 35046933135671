import { useState } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faGlobe, faBars} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Headsite = () => {
    
    const { t, i18n } = useTranslation('lang');

    const initialStateViews = { lenguage: false, services: false, menuMobile: false }
    const [views, setViews] = useState(initialStateViews);
    const { lenguage, services, menuMobile } = views;
    const navigate = useNavigate();

    const handleCloseServicesMenuWithAnimation = () => {
        const services = document.querySelector('.header_nav_services');
        services.classList.toggle('slideToDown');
        services.addEventListener('animationend', () => setViews({ ...initialStateViews}));
    }

    const handleCloseLenguagesWithAnimation = () => {
        const lenguages = document.querySelector('.header_top-bar_lenguages');
        lenguages.classList.toggle('slideToDownLenguages');
        lenguages.addEventListener('animationend', () => setViews({...initialStateViews}));
    }

    const handleCloseMainMenuWithAnimation = () => {
        const mainMenu = document.querySelector('.header_mobile-nav');
        const closeMenuFrame = document.querySelector('.headear_close-menu');
        closeMenuFrame.classList.toggle('fadeOut');
        mainMenu.classList.toggle('slideMenuToRight');
        mainMenu.addEventListener('animationend', () => setViews({...initialStateViews}));
        document.querySelector('html').style.overflowY = 'scroll';
    }

    const handleChangeLangUi = (lang) => {
        i18n.changeLanguage(lang).catch(err => console.log(err));
    }

    return (
        <header>
            <div className="header_top-bar">
                <div className="header_top-bar_item">
                    <FontAwesomeIcon icon={faEnvelope} className="header_top-bar_item_svg"/>
                    <span>contacto@digital-age.biz</span>
                </div>
                <div className="header_top-bar_item" onMouseOver={() => setViews({...initialStateViews, lenguage: true})} >
                    <FontAwesomeIcon icon={faGlobe} className="header_top-bar_item_svg"/>
                    <FontAwesomeIcon icon={faCaretDown} className="header_top-bar_item_svg-caret"/>
                </div>
                {
                    lenguage &&
                    <ul className="header_top-bar_lenguages" onMouseLeave={handleCloseLenguagesWithAnimation}>
                        <li onClick={() => handleChangeLangUi('en')}><img src="/img/flag-uk.png" alt="ingles"/> <span>{t('english')} </span></li>
                        <li onClick={() => handleChangeLangUi('es')}><img src="/img/flag-spain.png" alt="español"/>  <span>{t('spanish')} </span></li>
                    </ul>
                }
            </div>

            <nav className="header_nav">
                <img src="/img/logo.png" alt="logo" className="header_nav_logo" onClick={() => navigate('/')} />
                <ul className="header_nav_main-menu">
                    <li><Link to='/'>{t('home')}</Link></li>
                    <li
                        onMouseOver={() => setViews({ ...initialStateViews, services: true })}
                    >{t('services')}</li>
                    <li><Link to='/contact-us'>{t('contact')}</Link></li>
                </ul>
                {
                    services &&
                    <div className='header_nav_services' onMouseLeave={handleCloseServicesMenuWithAnimation}>

                        <div>
                            <span>DIGITAL</span>
                            <ul>
                                <li onClick={() => navigate('/services/content-marketing') }>{t('content_marketing')}</li>
                                <li onClick={() => navigate('/services/design') }>{t('design')}</li>
                                <li onClick={() => navigate('/services/web-development') }>{t('web_dev')}</li>
                            </ul>
                        </div>

                        <div>
                            <span>{t('marketing_digital')}</span>
                            <ul>
                                <li onClick={() => navigate('services/performance-marketing') } >{t('performance_marketing')}</li>
                                <li onClick={() => navigate('services/user-acquisition') }>{t('user_acquisition')}</li>
                                <li onClick={() => navigate('services/branding') } >{t('branding')}</li>
                                <li onClick={() => navigate('services/mobile-marketing') } >{t('mobil')}</li>
                            </ul>
                        </div>

                    </div>
                }
            </nav>

            <div className="header_mobile-header" >
                <img src="img/logo.png" alt="logo" onClick={ () => navigate('/')} />
                <FontAwesomeIcon icon={faBars} className="header_mobile-header_icon-menu" onClick={() => {
                    document.querySelector('html').style.overflowY = 'hidden';
                    setViews({...initialStateViews, menuMobile:true});
                }}/>
            </div>
            {
                menuMobile &&
                <nav className="header_mobile-nav">
                    <div className="header_mobile-nav_langs">
                        <img src="/img/flag-spain.png" alt="español" onClick={() => handleChangeLangUi('es')} />
                        <img src="/img/flag-uk.png" alt="ingles" onClick={() => handleChangeLangUi('en')} />
                    </div>
                    <ul>
                        <li><Link to='/contact-us'>{t('contact')}</Link></li>
                        <li><Link to='services/mobile-marketing'>{t('mobile_marketing')}</Link></li>
                        <li><Link to='services/user-acquisition'>{t('user_acquisition')} </Link></li>
                        <li><Link to='services/branding'>{t('branding')}</Link></li>
                        <li><Link to='services/performance-marketing'>{t('performance_marketing')}</Link></li>
                        <li><Link to='services/content-marketing'>{t('content_marketing')} </Link></li>
                        <li><Link to='services/design'>{t('design')}</Link></li>
                        <li><Link to='services/web-development'>{t('web_dev')}</Link></li>
                    </ul>
                    <img src="/img/logo.png" alt="logo" className="header_mobile-nav_logo"/>
                </nav>
            }
            {menuMobile && <div className="headear_close-menu" onClick={handleCloseMainMenuWithAnimation}></div>}
        </header>
    )
}

export default Headsite;