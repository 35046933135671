import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

const Error404 = () => {

    const {t} = useTranslation('lang');

    return(
        <main style={{marginBottom:'350px'}}>
            <section className="error404_container">
                <FontAwesomeIcon icon={faTriangleExclamation} className="error404_container_icon"/>
                <div>
                    <strong>404</strong>
                    <span>{t('404')}</span>
                </div>
            </section>
        </main>
    )
}

export default Error404;