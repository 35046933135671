import { useParams } from "react-router-dom";
import ServiceBanner from "../components/ServiceBanner";
import { useTranslation } from "react-i18next";

const Service = () => {

    const {t} = useTranslation('lang');
    const {typeService} = useParams();

    return(
       
        <main>
            {
                typeService === 'content-marketing'
                ?
                <ServiceBanner 
                    imgBanner={'/img/ilustracion_contenido-estrategico-380x380.png'}
                    imgAltBanner='marketing strategy'
                    backgroundBanner={'rgb(32, 164, 168)'}
                    titleBanner={t('services_content-marketing_title')}
                    descriptionBanner={t('services_content-marketing_p')}
                />
                :
                typeService === 'design'
                ?
                <ServiceBanner
                    imgBanner={'/img/ilustracion_disenio-380x380.png'}
                    imgAltBanner={'diseño'}
                    backgroundBanner={'rgb(251, 120, 20)'}
                    titleBanner={t('services_design_title')}
                    descriptionBanner={t('services_design_p')}
                />
                :
                typeService === 'web-development'
                ?
                <ServiceBanner
                    imgBanner={'/img/web-380x304.png'}
                    imgAltBanner={'web dev'}
                    backgroundBanner={'rgb(61, 156, 213)'}
                    titleBanner={t('services_web-dev_title')}
                    descriptionBanner={t('services_web-dev_p')}
                />
                :
                typeService === 'performance-marketing'
                ?
                <ServiceBanner
                    imgBanner={'/img/ilustracion_seo.png'}
                    imgAltBanner={'marketing performante'}
                    backgroundBanner={'rgb(255, 152, 0)'}
                    titleBanner={t('services_perf-marketing_title')}
                    descriptionBanner={t('services_perf-marketing_p')}
                    descriptionPage={t('services_perf-marketing_page_p')}
                />
                :
                typeService === 'user-acquisition'
                ?
                <ServiceBanner
                    imgBanner={'/img/ilustracion_quienes-somos.png'}
                    imgAltBanner={'usuarios'}
                    backgroundBanner={'rgb(35, 97, 160)'}
                    titleBanner={t('services_user-acq_title')}
                    descriptionPage={t('services_user-acq_page_p')}
                />
                :
                typeService === 'branding'
                ?
                <ServiceBanner
                    imgBanner={'/img/ilustracion_branding.png'}
                    imgAltBanner={'branding'}
                    backgroundBanner={'rgb(15, 55, 83)'}
                    titleBanner={t('services_branding-title')}
                    descriptionPage={t('services_branding_page-p')}
                />
                :
                typeService === 'mobile-marketing'
                ?
                <ServiceBanner
                    imgBanner={'/img/services-mobile-marketing.png'}
                    imgAltBanner={'tecnologia mobil'}
                    backgroundBanner={'rgb(39, 76, 96)'}
                    titleBanner={t('services_mobile-marketing_title')}
                    descriptionBanner={t('services_mobile-marketing_p')}
                    descriptionPage={t('services_mobile-marketing_page-p') }
                />
                :
                <ServiceBanner 
                    imgBanner={'/img/ilustracion_contenido-estrategico-380x380.png'}
                    imgAltBanner='marketing strategy'
                    backgroundBanner={'rgb(32, 164, 168)'}
                    titleBanner={t('services_content-marketing_title')}
                    descriptionBanner={t('services_content-marketing_p')}
                />
            }
            
        </main>
        
    )
}

export default Service;