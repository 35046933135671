import FormMail from "./FormMail"
import { useTranslation } from "react-i18next";

const ServiceBanner = ({ imgBanner, imgAltBanner, backgroundBanner, titleBanner, descriptionBanner, descriptionPage }) => {
    const {t} = useTranslation('lang');
    return (
        <div className="service">
            <section style={{ backgroundColor: backgroundBanner }} className="service_banner" >
                <img src={imgBanner} alt={imgAltBanner} />
                <h1>{titleBanner}</h1>
                {descriptionBanner && <p>{descriptionBanner}</p>}
            </section>
            {descriptionPage && <p>{descriptionPage}</p>}
            <section className="service_form">
                <h2>{t('lets_talk_bussines')}</h2>
                <FormMail/>
            </section>
        </div>
    )
}

export default ServiceBanner;