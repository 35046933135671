import FormMail from "../components/FormMail";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

const ContactUs = () => {

    const {t} = useTranslation('lang');

    return(
        
        <main style={{marginBottom:'13vh'}}>
            <section className="contact_container">
                <div className="contact_container_info">
                    <ul>
                        <li style={{color:'var(--light-blue-2)'}} > <FontAwesomeIcon icon={faEnvelope}/> <span>contacto@digital-age.biz</span></li>
                        <li> <FontAwesomeIcon icon={faLocationDot}/> <address>Av. Cerviño 4502 <br/> Piso 20 Departamento RIO <br/> Ciudad Autónoma de Buenos Aires <br /> Argentina </address></li>
                    </ul>
                </div>
                <div className="contact_container_form">
                    <h1>{t('contact-title_form')}</h1>
                    <FormMail/>
                </div>
            </section>
        </main>
    )
}

export default ContactUs;