import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenRuler, faSuitcase, faShoppingCart} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {

    const{t} = useTranslation('lang');
    const navigate = useNavigate();

    return (
        
        <main>

            <section className="main_home-video">
                <video src="/video/reel.mp4" loop autoPlay muted></video>
            </section>

            <section className="main_home-marketing">

                <h1>{t('marketing_digital')}</h1>

                <div className="main_home-marketing_cards">

                    <div className="main_home-marketing_cards_brand" onClick={() => navigate('/services/branding')} >
                        <img src="/img/ilustracion_branding.png" alt="branding" />
                        <span>{t('branding')}</span>
                    </div>

                    <div className="main_home-marketing_cards_mobile">
                        <img src="/img/services-mobile-marketing.png" alt="mobile" onClick={() => navigate('/services/mobile-marketing')} />
                        <span>{t('mobil')}</span>
                    </div>

                    <div className="main_home-marketing_cards_marketing" onClick={() => navigate('/services/performance-marketing')}>
                        <img src="/img/ilustracion_seo.png" alt="performance" />
                        <span>{t('performance_marketing')}</span>
                    </div>

                    <div className="main_home-marketing_cards_user" onClick={() => navigate('/services/user-acquisition')} >
                        <img src="/img/ilustracion_SEM_Ad_usuarios1.png" alt="adquisicion usuarios" />
                        <span>{t('user_acquisition')}</span>
                    </div>

                </div>

            </section>

            <section className="main_home-about-us">
            <img src="/img/ilustracion_quienes-somos.png" alt="quienes somos" />
                <div className="main_home-about-us_info">
                    <h1>{t('about_us_title')}</h1>
                    <p>{t('about_us_p')}</p>
                </div>
            </section>

            <section className="main_home-other-services">

                <h1>{t('other_services_title')}</h1>

                <div className="main_home-other-services_container">

                    <div className="main_home-other-services_container_column">

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faPenRuler} className="icon-services"/>
                                <h2>{t('other_services_production_title')} </h2>
                            </div>
                            <p>{t('other_services_production_p')}</p>
                        </div>

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faSuitcase} className="icon-services blueIcon"/>
                                <h2>{t('other_services_technical_title')}</h2>
                            </div>
                            <p>{t('other_services_technical_p')}</p>
                        </div>

                    </div>

                    <div className="main_home-other-services_container_column">

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faShoppingCart} className="icon-services blueIcon" id="shopIcon"/>
                                <h2>{t('other_services_marketing_title')}</h2>
                            </div>
                            <p>{t('other_services_marketing_p')} </p>
                        </div>

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faSuitcase} className="icon-services"/>
                                <h2>{t('other_services_autation_title')} </h2>
                            </div>
                            <p>{t('other_services_autation_p')}</p>
                        </div>

                    </div>
                    
                </div>

            </section>
            
        </main>
        
    )
}

export default Home;