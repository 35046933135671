import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const {t} = useTranslation('lang');

    return (
        <footer>
            <div className="footer_copyright">
                <FontAwesomeIcon icon={faChevronUp} className="footer_copyright_to-up" onClick={() => window.scroll(0, 0)} />
                <span style={{ color: '#a1a3a4' }} >{t('copyright')}</span>
            </div>
        </footer>
    )
}

export default Footer;