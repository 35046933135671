import Swal from 'sweetalert2';
import useForm from "../hooks/useForm";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from 'react-google-recaptcha';

const FormMail = () => {

    const {t} = useTranslation('lang');
  
    const{handleFormChange, handleResetForm, inputValues} = useForm({name:'', email:'', message:''});
    const {name,email,message} = inputValues;
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const captchaValue = window.grecaptcha.getResponse()
        if (!captchaValue || !(name.length) || !(email.length) || !(message.length) ) {
            return Swal.fire({
                    title: 'Form incomplete',
                    text: t('form_incomplete'),
                    icon: 'error',
                    confirmButtonText: 'Cool'
                })
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        fetch(window.origin+'/envio.php', {
            method:'POST',
            body: formData
        })
        .then( resp => {
            Swal.fire({
                title: 'Email sended',
                text: t('email_sended'),
                confirmButtonText: 'Cool'
            })
            console.log(resp)
        })
        .catch( err => {
            Swal.fire({
                title: 'Error',
                text: 'Error',
                confirmButtonText: 'Cool'
            })
            console.log(err)
        })
        handleResetForm();
        window.grecaptcha.reset()
    }

    return (
        <form action="" className="form-mail" autoComplete="off" onSubmit={handleSubmit}>

            <div>
                <label htmlFor="name">{t('name_form')}</label>
                <strong>*</strong>
            </div>
            <input 
                type="text" 
                onChange={handleFormChange} 
                value={name} 
                id="name" 
                name="name" 
            />

            <div>
                <label htmlFor="email">{t('email_form')}</label>
                <strong>*</strong>
            </div>
            <input 
                type="email" 
                onChange={handleFormChange} 
                value={email} 
                id="email" 
                name="email" 
            />

            <div>
                <label htmlFor="message">{t('message_form')}</label>
                <strong>*</strong>
            </div>
            <input 
                type="text" 
                onChange={handleFormChange} 
                value={message} 
                id="message" 
                name="message" 
            />

            <ReCAPTCHA 
                sitekey='6LcGAwMhAAAAALa2drvxlqJe0HK7WBmkgvNdvS_8'
            />

            <button type="submit">{t('send_form')}</button>

        </form>
    )
}

export default FormMail;