import { useState } from "react";

const useForm = (initialState) => {
    
    const[inputValues, setInputValues] = useState({});
    
    const handleFormChange = ({target}) => {
        setInputValues({
            ...inputValues,
            [target.name]:target.value
        })
    }

    const handleResetForm = () => setInputValues(initialState);

    return {
        handleFormChange,
        handleResetForm,
        inputValues
    }

}

export default useForm;